@font-face {
  font-family: "rsuite-icon-font";
  src: url("fonts/rsuite-icon-font.ttf") format("truetype"), url("fonts/rsuite-icon-font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.icon {
  position: relative;
  font-size: 12px;
}

.icon:after {
  font-style: normal;
  line-height: 1;
  content: "";
  font-family: "rsuite-icon-font";
}

.icon.close::after {
  content: "\f00d";
}

.icon.edit::after {
  content: "\e913";
}

.icon.exchange::after {
  transform: rotateZ(90deg);
  content: "\f0ec";
}

.icon.trash::after {
  content: "\f014";
}

.icon.check::after {
  content: "\f00c";
}

.icon.filter::after {
  content: "\f0b0";
}

.icon.arrow-circle-left::after {
  content: "\f0a8" /* rtl: "\f0a9" */;
}

.icon.sequence::after {
  content: "\ea24";
}

.icon.sequence-down::after {
  content: "\f0dd";
}

.icon.sequence-up::after {
  content: "\f0de";
}

.icon.bars::after {
  content: "\f0c9";
}

.icon.cash::after {
  content: "\f155";
}

.icon.config::after {
  content: "\e918";
}

.icon.info::after {
  content: "\ea19";
}

.icon.doc::after {
  content: "\f0f6";
}

.icon.upload::after {
  content: "\e912";
}

.icon.user::after {
  content: "\f2c0";
}

.icon.mail::after {
  content: "\f003";
}

.icon.spinner::after {
  content: " ";
  display: block;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #169de0; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.icon.exclamation::after {
  content: "\f12a";
}

.icon.resend::after {
  content: "\f112";
}

.icon.file::after {
  content: "\f1c1";
}

.icon.link::after {
  content: "\f0c1";
}

.icon.bold::after {
  content: "\f032";
}

.icon.italic::after {
  content: "\f033";
}

.icon.underlined::after {
  content: "\f0cd";
}

.icon.ul::after {
  content: "\f0ca";
}

.icon.ol::after {
  content: "\f0cb";
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

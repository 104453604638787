.sidebar {
  background-color: #272c36 !important;
  width: 50px;
  position: fixed;
  height: calc(100vh - 56px);
  transition: width ease-in-out 400ms, left ease-in-out 400ms;
  padding: 10px;
  z-index: 2;
}

.rotated {
  transform: rotateZ(180deg);
  display: block;
}

.sidebar h3 {
  font-size: 18px;
  color: #d5d5d5;
  padding-left: 10px;
  overflow: hidden;
  width: 0;
  transition: all ease-in-out 350ms;
}

.toggle-button {
  display: block;
  color: white;
  margin-right: 0 !important;
  margin-left: auto !important;
  position: absolute;
  top: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #41464e;
  border-radius: 4px;
  transition: all ease-in-out 100ms, right ease-in-out 400ms;
}

.toggle-button:hover {
  color: white;
  background-color: #41454e;
}

.toggle-button i {
  display: block;
  line-height: 20px;
  font-size: 20px;
}

.nav-element {
  color: #d5d5d5;
  user-select: none;
  width: 36px;
  height: 36px;
  margin-left: -3px;
  padding: 7px 10px;
  padding-left: 13px;
  border-radius: 50%;
  margin-top: 5px;
  transition: all ease-in-out 200ms, overflow ease-in-out 0s 400ms;
  font-size: 16px;
  font-style: normal;
  font-weight: bolder;
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: visible;
  align-items: center;
}
.nav-element.active {
  background-color: #169de0;
  color: #fff;
}
.nav-element.not-updating {
  background-color: #e04949 !important;
  color: #fff;
}

.nav-element:nth-of-type(10),
.nav-element:nth-of-type(12),
.nav-element:nth-of-type(13) {
  padding-left: 9px;
}

.nav-element:nth-of-type(11) {
  padding-left: 10px;
}

.nav-element:hover {
  background-color: #ffffff3a;
}

.nav-element.active:hover {
  background-color: #2eacea;
}

.nav-element i {
  position: absolute;
  opacity: 0;
  left: -250px;
  top: 5px;
  background-color: #272c36;
  padding: 3px 7px;
  border-radius: 4px;
  display: inline-block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin-left: 10px;
  transition: opacity ease-in-out 150ms, transform ease-in-out 200ms;
  white-space: nowrap;
}

.nav-element i:before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #272c36;
  color: #d5d5d5;
  position: absolute;
  left: -3px;
  top: 50%;
  transform: translateY(-50%) rotateZ(45deg);
}

.sidebar:not(.sidebar-expanded) .nav-element.shortcut i,
.sidebar:not(.sidebar-expanded) .nav-element:hover i {
  left: 28px;
  transform: translateX(10px);
  opacity: 1;
}

.sidebar.sidebar-expanded h3 {
  width: 100%;
}

.sidebar.sidebar-expanded .nav-element {
  width: 100%;
  height: auto;
  margin-left: 0px;
  padding: 7px 10px;
  border-radius: 4px;
  overflow: hidden;
}

.sidebar.sidebar-expanded .nav-element i {
  position: initial;
  opacity: 1;
  background-color: transparent;
  padding-top: 4px;
  padding: 0;
  transition-delay: 0ms;
}

.breadcrumb {
  display: none;
}

.sidebar.sidebar-expanded .nav-element i:before {
  content: none;
}

.sidebar.sidebar-expanded {
  width: 165px;
  left: 0;
}

.sidebar-container .mobile-title {
  display: block;
  font-size: 18px;
  /* padding-top: 2px; */
  color: hsl(220, 5%, 45%);
  font-weight: 500;
  padding-left: 10px;
  transition: all ease-in-out 350ms;
}

.sidebar-container .mobile-title span {
  font-size: 11px;
  padding-left: 4px;
  color: hsl(220, 5%, 55%);
}

@media (max-width: 600px) {
  .sidebar {
    left: -50px;
  }

  .breadcrumb {
    display: block;
    position: absolute;
    width: 100%;
    background: #efeff6;
    padding: 18px 20px 10px 50px;
  }

  .sidebar:not(.sidebar-expanded) .toggle-button {
    right: -40px;
  }
}

nav {
  box-shadow: 0px 0px 28px 0px rgba(82, 63, 105, 0.13);
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 3;
  /* display: flex; */
  /* z-index: 2; */
  height: 56px;
  overflow: hidden;
  transition: height ease-in-out 400ms;
}

nav.open {
  height: 112px;
}

nav .nav-header {
  position: absolute;
  display: inline-block;
  height: 56px;
}

nav .navbar-brand {
  color: #3b3b3b !important;
  font-size: 16px;
  padding: 16px 20px;
  display: inline-block;
}

nav .nav-main {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding-left: 150px;
}

nav.open .nav-body {
  display: flex;
  flex-direction: column-reverse;
}

nav.open ul {
  display: inline-flex;
}

nav .nav-body .selects {
  display: inline-flex;
  grid-gap: 12px;
  gap: 12px;
}

nav .nav-right {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

nav .avatar-div {
  width: 56px;
  height: 56px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

nav .nav-burger {
  margin-left: 5px;
  display: block;
}
nav .navbar-space {
  height: calc(115px);
  transition: height ease-in-out 400ms;
}

nav .navbar-space.open {
  height: 112px;
}

.nav-body {
  display: block;
  width: 100%;
  justify-content: center;
}

nav ul {
  display: inline-block;
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 56px;
}

nav li {
  line-height: 56px;
  display: inline-block;
  vertical-align: top;
  height: 100%;
  padding: 0 14px;
  font-size: 14px;
  /* line-height: 1.42857143; */
  cursor: pointer;
  color: #8e8e93;
  transition: color 0.3s linear, background-color 0.3s linear;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

nav li:hover {
  background-color: #f7f7f7;
}

nav li.active {
  color: #000;
}

nav .select-div {
  height: 56px;
  display: inline-flex;
  align-items: center;
  /* margin-left: 10px;
  margin-right: 10px; */
}

nav .select-div select {
  color: #848488;
  border-color: #d2d2d4;
}

.navbar-space {
  height: 56px;
  transition: height ease-in-out 400ms;
}

.navbar-space.open {
  height: 112px;
}

.avatar {
  background-color: rgb(22, 157, 224);
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40px;
  height: 40px;
  font-size: 18px;
}

@media (max-width: 650px) {
  nav {
    height: 110px;
  }

  nav.open {
    height: 160px;
  }

  nav .nav-main {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 0px;
  }

  nav .nav-body {
    display: flex;
    flex-direction: row;
  }

  nav .nav-body ul {
    display: none;
    line-height: 40px;
  }

  nav.open .nav-body {
    display: flex;
    flex-direction: column-reverse;
  }

  nav.open .nav-body ul {
    width: 100%;
    display: inline-block;
    justify-content: center;
  }

  nav .nav-body ul li {
    width: 50%;
    text-align: center;
    padding: 15px 15px 10px 15px !important;
    height: 40px !important;
    line-height: 1;
  }

  nav .nav-body ul li:hover {
    background-color: #f6f6f6;
  }

  nav .nav-body .selects {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  nav .select-div {
    height: 50px;
  }

  nav .nav-body .rs-picker-select {
    padding: 0 20px;
    width: 100% !important;
  }

  .navbar-space {
    height: 110px;
  }

  .navbar-space.open {
    height: 160px;
  }
}

@media (min-width: 920px) {
  nav {
    display: flex;
  }

  nav .nav-main {
    flex-grow: 1;
    padding-left: 0px;
  }

  nav .select-div {
    margin-right: 0;
  }

  nav .select-div select {
    width: 180px;
  }

  nav .nav-burger {
    display: none;
  }

  nav .nav-header {
    position: relative;
  }
  nav .nav-body ul {
    display: inline-flex;
  }

  nav .nav-body .selects {
    display: inline-flex;
    gap: 12px;
  }

  nav .nav-main {
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  nav.open {
    height: 56px;
  }

  .navbar-space.open {
    height: 56px;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #575757;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  min-height: calc(100vh - 56px);
  background-color: #efeff6;
  margin-left: 0px;
  padding: 25px 40px 0 40px;
  transition: margin-left ease-in-out 400ms;
}

.sidebar-container .container {
  margin-left: 50px;
}

.secondbar-container .container {
  min-height: calc(100vh - 56px - 48px);
}

.sidebar-container.sidebar-expanded .container {
  margin-left: 165px;
}

button {
  border: none;
}

a {
  text-decoration: none;
}

h3 {
  font-size: 28px;
  line-height: 42px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: bolder;
  color: inherit;
  margin: 0;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input {
  line-height: normal;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

.rs-btn-default {
  background: #ececec;
}

.rs-panel-heading {
  padding: 10px 15px;
}
.rs-panel-body-fill {
  overflow-x: auto;
}

.rs-alert.rs-alert-container {
  z-index: 3;
  bottom: 10px !important;
  right: -90px !important;
  top: auto !important;
  left: auto !important;
}

.form-group > div > div:first-of-type {
  border: 1px solid #e5e5ea;
  transition: all none, border-color ease-in-out 0.3s;
  border-radius: 6px;
  margin-bottom: 24px;
}

.form-group > div > div:first-of-type:focus,
.form-group > div > div:first-of-type:hover {
  box-shadow: none;
  border-color: #1675e0;
  outline: 0;
}

.form-group > div > div:first-of-type span {
  display: none;
}

.form-group > div > div:first-of-type svg {
  width: 15px;
  color: #777c85;
  height: 15px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 56px);
    margin-left: 0;
    padding: 25px 20px 0 20px;
  }

  .sidebar-container .container {
    margin-left: 0px;
    padding-top: 60px;
  }

  .secondbar-container .container {
    min-height: calc(100vh - 56px - 44px);
  }

  .sidebar-container.sidebar-expanded .container {
    margin-left: 0px;
  }
}

@media (max-width: 1400px) {
  .rs-panel-heading {
    padding: 8px 10px;
  }

  .rs-panel-heading button {
    padding: 2px 8px 3px 8px;
    line-height: 1.4;
  }
}

.text-red {
  color: red !important;
}

tr td.text-red span.neg {
  color: red !important;
}

.edificio {
  padding-top: 25px;
  padding-bottom: 50px;
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 25px;
}

.edificio .step-header h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #111827;
}

.edificio .step-header h4 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #4b5563;
  margin-top: 4px;
}

.edificio .panel {
  grid-column: 2 / -1;
}

.edificio hr {
  margin: 0;
  border-width: 1px 0 0 0;
  width: 100%;
  color: #fff;
  border-color: rgb(0 0 0 / 5%);
  grid-column: 1 / -1;
}

.edificio .panel .footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 12px 24px;
  /* margin-top: -24px; */
  background-color: #fafafa;
}

.edificio .form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 20px;
  padding: 32px 24px 42px 24px;
}

.edificio .form-grid .form-group:nth-child(2n + 1),
.edificio .form-grid .form-group {
  margin: 0 !important;
}

@media (max-width: 650px) {
  .edificio .form-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 900px) {
  .edificio {
    grid-template-columns: 1fr;
  }

  .edificio .panel {
    grid-column: 1 / 2;
  }
}

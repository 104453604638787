.user-card {
  position: absolute;
  background-color: #fff;
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.2));
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  opacity: 0;
  z-index: 1;
  transform: translateX(-50%) translateY(40px);
  transition: top ease-in-out 300ms, left ease-in-out 300ms, opacity ease-in-out 200ms;
}

.user-card.open {
  opacity: 1;
}

@keyframes userCard {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.user-card::after {
  content: "";
  background-color: #fff;
  display: block;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
  border-radius: 60px;
  width: 60px;
  height: 60px;
  z-index: -1;
}

.user-card-header {
  position: relative;
  background-color: #169de0;
  border-radius: 6px 6px 0 0;
  padding-top: 40px;
  padding-bottom: 10px;
  color: #fff;
  text-align: center;
}

.user-card-header button {
  background-color: transparent;
  position: absolute;
  top: 5px;
  right: 5px;
}

.user-card .icon-div {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #169de0;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-card .body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.user-card .body p {
  padding-right: 10px;
}

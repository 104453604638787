.secondbar {
  background-color: #272c36 !important;
  width: 100%;
  position: fixed;
  height: 48px;
  transition: height ease-in-out 400ms, left ease-in-out 400ms;
  padding: 9px 10px;
  z-index: 2;
}

.secondbar.sidebar-expanded {
  height: auto;
}

.secondbar-space {
  height: 44px;
}

.rotated {
  transform: rotateZ(180deg);
  display: block;
}

.secondbar .mobile-title {
  display: block;
  font-size: 18px;
  padding-top: 2px;
  color: hsl(220, 5%, 80%);
  font-weight: 500;
  padding-left: 10px;
  transition: all ease-in-out 350ms;
}

.secondbar .mobile-title span {
  font-size: 11px;
  padding-left: 4px;
  color: hsl(220, 5%, 65%);
}

.secondbar .toggle-button {
  display: block;
  color: white;
  margin-right: 0 !important;
  margin-left: auto !important;
  position: absolute;
  top: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: #41464e;
  border-radius: 4px;
  transition: all ease-in-out 100ms, right ease-in-out 400ms;
}

.sidebar .toggle-button:hover {
  color: white;
  background-color: #41454e;
}

.sidebar .toggle-button i {
  display: block;
  line-height: 20px;
  font-size: 20px;
}
.tabs {
  position: relative;
  display: flex;
  z-index: 1;
  padding-left: 0;
  margin: 0;
  overflow: auto;
}

.tabs li {
  display: block;
  user-select: none;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  margin-left: 10px;
  cursor: pointer;
}

.tabs.tabs-pills li {
  color: hsl(220, 5%, 70%);
  white-space: nowrap;
  width: 100px;
  font-weight: 600;
  font-size: 13px;
  width: auto;
  padding: 0px 10px;
  border-radius: 6px;
  transition: background-color ease-in-out 200ms;
}

.tabs.tabs-pills li:not(.active):hover {
  color: #fff;
  background-color: #353b46 !important;
}

.tabs.tabs-pills li.active {
  color: #fff;
  background-color: #169de0;
}

.tabs.tabs-folder li {
  border-radius: 6px 6px 0 0;
  color: #575757;
  white-space: nowrap;
  width: 105px;
  background-color: #d7dade;
}

.tabs.tabs-folder li.active {
  background-color: #fff;
}

.mobile-tab {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.mobile-tab.tabs-folder {
  position: relative;
  background-color: #d7dade;
  border: 1px solid #575757;
  padding: 10px 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.mobile-tab svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}

@media (max-width: 600px) {
  .tabs {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.panel {
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 0 rgb(101 107 113 / 5%), 0 4px 8px rgb(129 135 142 / 6%),
    0 10px 10px rgb(128 137 146 / 3%), 0 7px 14px 0px hsla(212, 6%, 45%, 0.052);
}

.panel.panel-flat-shadow {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

.panel.panel-bordered {
  border: 1px solid #e5e5ea;
  box-shadow: none;
}

.panel-header {
  padding: 10px 15px;
  color: #272c36;
  font-size: 16px;
  line-height: 1.25;
  overflow-y: auto;
}

.panel-header > div {
  overflow-y: auto;
}

.panel-body {
  overflow: auto;
  border-top: 1px solid #f2f2f5;
}

.panel-body::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

/* Track */
.panel-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
.panel-body::-webkit-scrollbar-thumb {
  background: rgb(195, 195, 195);
  border-radius: 4px;
}

/* Handle on hover */
.panel-body::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 175, 175);
}

.panel.headless .panel-body {
  border-top: none;
}

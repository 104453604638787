.RichEditor-root {
  background: #fff;
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-top: 15px;
}

.RichEditor-root .controls-box {
  display: flex;
  background: #ebebf0;
  border-radius: 6px;
  padding: 5px 15px 0px 15px;
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  padding: 2px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: calc(100vh - 500px);
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  /* font-family: "Hoefler Text", "Georgia", serif; */
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  /* font-family: "Inconsolata", "Menlo", "Consolas", monospace; */
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.sueldo {
  background-color: #fff;
  border-radius: 7px;
  margin-bottom: 20px;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.sueldo .header {
  display: flex;
  /* align-items: center; */
  height: 50px;
  padding: 10px 20px;
  justify-content: space-between;
}

.sueldo .body {
  padding: 20px 20px;
}

.sueldo .body .info {
  width: 70%;
  margin: 0px auto;
}

.sueldo .body .info th {
  width: 33%;
  border: none;
  font-weight: 700;
}

.sueldo .body .info td {
  border: none;
  text-align: center;
}

.sueldo .body .info tr:hover td {
  background-color: #fff;
}

.sueldos-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}

.my-masonry-grid {
  display: flex;
  margin-left: -20px;
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}

@media (min-width: 1350px) {
  .sueldos-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1900px) {
  .sueldos-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

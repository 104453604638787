.table {
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  color: #8e8e93;
  background-color: #fff;
  font-size: 14px;
  border-collapse: collapse;
  white-space: nowrap;
}

body > tr {
  display: table-row;
}

th,
td {
  font-weight: 400;
  padding: 3px 10px;
  border: 1px solid #f2f2f5;
}

thead tr:first-of-type th,
thead tr:first-of-type th {
  border-top: 0;
}

.panel th:first-of-type,
.panel td:first-of-type {
  border-left: none;
}

.panel th:last-of-type,
.panel td:last-of-type {
  border-right: none;
}

.panel tbody tr:last-of-type th,
.panel tbody tr:last-of-type td {
  border-bottom: none;
}

th {
  position: relative;
}

th.table-th {
  padding-right: 20px;
}

th.th-filter {
  position: sticky;
  border: 0;
  top: -1px;
  background-color: #fff;
  z-index: 1;
}

td {
  color: #575757;
}

.text-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sort-icon {
  position: absolute;
  right: 5px;
}

.sort-icon i::after {
  font-size: 12px;
  width: 10px;
  height: 10px;
  color: rgb(22, 157, 224);
}

.finalizado td:not(.actions-col) {
  color: #08b308;
}

.eliminado td:not(.actions-col) {
  color: #da0000;
}

.rs-btn {
  margin-right: 5px;
}

.actions-col {
  width: 110px;
  padding: 2px 10px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

tr:hover td {
  background-color: #f7f7fa;
}

.th-filter {
  padding: 0 0 1px 0 !important;
  overflow: hidden;
}

.th-filter div {
  height: 33px;
}

.th-filter.shadow {
  padding: 0 0 4px 0 !important;
}

.th-filter.shadow div {
  border-top: 1px solid #f2f2f5;
  box-shadow: -1px 2px 3px 1px #00000026;
}

th.th-filter input {
  position: absolute;
  width: calc(100% - 6px);
  top: 0;
  left: 3px;
  padding: 5px 11px !important;
  margin-top: 3px !important;
  font-size: 14px !important;
  line-height: 1 !important;
}

td.order-td {
  margin: 0;
  padding: 0;
  width: 80px;
}

td.order-td input {
  text-align: center;
  width: 100%;
  line-height: 1;
  padding: 5px;
  margin-right: -8px;
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 12px;
}
td.order-td input:hover,
td.order-td input:focus {
  border-color: #1675e0;
}

input:hover,
input:focus {
  outline: 0;
}

.table input::placeholder {
  color: #c3c3c3;
}

tr td span.neg {
  color: #0397e0 !important;
}

tr.header.text-error td {
  background: #f38787 !important;
  color: #fff !important;
}

tr.header td {
  font-weight: 700 !important;
  background-color: #ecf0f3 !important;
}

tr.total td {
  position: sticky;
  bottom: 0;
  border: 0;
  font-weight: 700 !important;
  background-color: #ecf0f3 !important;
}

tr.separator td {
  font-weight: 700 !important;
  color: #ffffff;
  background-color: #1781b5 !important;
  border-color: #1781b5;
  padding: 0 10px;
}

tr.separator td span.neg {
  color: #ffffff !important;
}

tr.selected {
  background: #ffb3b3;
  /* animation: selectedAnimation ease-in-out 1500ms; */
}

@keyframes selectedAnimation {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: hsl(200, 100%, 92%);
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: hsl(200, 100%, 92%);
  }
  100% {
    background-color: #fff;
  }
}

@media (max-width: 1400px) {
  td {
    padding: 1px 10px;
    font-size: 13px;
  }

  .actions-col {
    padding: 1px 10px;
  }

  .actions-col button {
    height: 20px;
    width: 20px;
  }

  .actions-col .rs-btn-xs.rs-btn-icon > .rs-icon {
    height: 20px;
    width: 20px;
    font-size: 10px;
  }
}
